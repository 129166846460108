<style>
body {
  margin: 0;
}
.mask {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  bottom: 0;
  background: #333;
  width: 100%;
}
.mask img {
  max-width: 100%;
  height: auto;
}
#app {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  min-height: 100vh;
  padding-bottom: 100px;
}
.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.tips {
  font-size: 18px;
  color: #666;
}
.tips-ip {
  margin-bottom: 0;
  font-size: 26px;
  font-weight: bold;
  color: #999;
}
.tips-ts {
  margin-top: 0;
  font-size: 15px;
  color: #999;
}
.btn {
  font-size: 20px;
  height: 50px;
  min-width: 180px;
  background-color: #53c957;
  border-color: #53c957;
  border-radius: 4px;
  color: #fff;
  box-shadow: none;
  border: none;
}
.site-title {
  padding: 16px 0;
  text-align: center;
  font-weight: bold;
  color: #666;
}
.xlcontainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1100px;
}
.xlbtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  margin: 8px 12px;
  min-width: 130px;
  flex-shrink: 0;
  border: 1px solid #eee;
  border-radius: 4px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  font-size: 12px;
  font-weight: bold;
  color: #333;
  cursor: no-drop;
}
.xbtn-copy {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  margin-right: -8px;
  margin-top: -4px;
  margin-bottom: -4px;
  width: 26px;
  height: 26px;
  background: #eee;
  border-radius: 50%;
  cursor: pointer;
  transition: background 0.1s;
}
.xbtn-copy:hover {
  background: #666;
}
.xbtn-copy-img {
  width: 16px;
  height: 16px;
}
.xlbtn-success {
  border-color: #53c957;
  cursor: pointer;
}
/* .xlbtn-success-def {
  cursor: pointer;
} */
.xlbtn-warn {
  border-color: #ffc107;
  cursor: pointer;
}
.xlbtn-err {
  border-color: #f44336;
  cursor: no-drop;
}
.tipsicon {
  width: 8px;
  height: 8px;
  background: #000;
  border-radius: 50%;
}
.tipstext {
  margin: 0 6px;
  flex-grow: 1;
  text-align: right;
  font-size: 12px;
  font-weight: bold;
  color: #666;
}
.tipstext-success {
  background: #53c957;
}
.tipstext-success-warn {
  background: #ffc107;
}
.tipstext-success-err {
  background: #f44336;
}
</style>

<template>
  <div id="app">
    <div v-if="isWeixin() != 0" class="mask">
      <img
        src="./assets/image/vvv.png"
        alt=""
        v-if="isiOS"
      />
      <img
        src="./assets/image/qqq.png"
        alt=""
        v-if="isAndroid"
      />
    </div>
    <div v-else>
      <div v-if="type == 1">
        <div class="main">
          <p class="tips-ip" v-if="cip">{{ cip }}</p>
          <div
            v-for="config in domainCdnEveryStateConfig"
            :key="`${config.siteCode}${config.id}`"
          >
            <div class="site-title">
              {{ config.alias ? config.alias : config.siteCode }}
            </div>
            <div class="xlcontainer">
              <div
                class="xlbtn"
                :data-info="item.domain"
                :data-info-jumpLink="item.jumpLink"
                :class="{
                  'xlbtn-success': (item.state == 2 && item.ping < 2000) || isCheck == 0,
                  'xlbtn-warn': item.state == 2 && item.ping >= 2000,
                  'xlbtn-err': item.state == 3 || item.state == 4,
                }"
                @click="
                  item.state == 2
                    ? goSite(item)
                    : null
                "
                v-for="item in config.paths"
                :key="item.id"
              >
                <div>{{ item.alias }}</div>
                <div class="tipstext" v-if="item.state == 1">检测中.</div>
                <div class="tipstext" v-else-if="item.state == 2">
                  {{ item.ping }}ms
                </div>
                <div class="tipstext" v-else-if="item.state == 3">超时</div>
                <div class="tipsicon" v-if="item.state == 1"></div>
                <div
                  class="tipsicon tipstext-success-warn"
                  v-else-if="item.state == 2 && item.ping >= 2000"
                ></div>
                <div
                  class="tipsicon tipstext-success"
                  v-else-if="item.state == 2 || isCheck == 0"
                ></div>
                <div
                  class="tipsicon tipstext-success-err"
                  v-else-if="item.state == 3 || item.state == 4"
                ></div>

                <div
                  v-if="(item.isEnableCopyBtn==1)"
                  class="xbtn-copy"
                  @click.stop="
                    copy(item.jumpLink, `已复制(${item.jumpLink})`)
                  "
                >
                  <img class="xbtn-copy-img" src="./icon/copy-link.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div v-if="optional == 1">
          <div class="main">
            <p class="tips-ip" v-if="cip">{{ cip }}</p>
            <p class="tips-ts">
              温馨提示：延迟数值越低的节点，访问网站速度越快。
            </p>
            <div
              v-for="config in domainEveryStateConfig"
              :key="`${config.siteCode}${config.id}`"
            >
              <div class="site-title">
                {{ config.alias ? config.alias : config.siteCode }}
              </div>
              <div class="xlcontainer">
                <div
                  class="xlbtn"
                  :data-info="item.domain"
                  :data-info-jumpLink="item.jumpLink"
                  :class="{
                    'xlbtn-success': (item.state == 2 && item.ping < 2000) || isCheck == 0,
                    'xlbtn-warn': item.state == 2 && item.ping >= 2000,
                    'xlbtn-err': item.state == 3 || item.state == 4,
                  }"
                  @click="
                    item.state == 2 || isCheck == 0 ? goSite(item) : null
                  "
                  v-for="item in config.domains"
                  :key="item.id"
                >
                  <div>线路{{ item.index + 1 }}</div>
                  <div class="tipstext" v-if="item.state == 1">检测中.</div>
                  <div class="tipstext" v-else-if="item.state == 2">
                    <span v-if="item.pingText" :style="{'color': item.pingTextColor}">{{ item.pingText }}</span>
                    <span v-else>{{ item.ping }}ms</span>
                  </div>
                  <div class="tipstext" v-else-if="item.state == 3">超时</div>
                  <div class="tipstext" v-else-if="item.state == 4">受限</div>
                  <div class="tipstext" v-else>
                    <span v-if="item.pingText" :style="{'color': item.pingTextColor}">{{ item.pingText }}</span>
                  </div>
                  <div class="tipsicon" v-if="item.state == 1"></div>
                  <div
                    class="tipsicon tipstext-success-warn"
                    v-else-if="item.state == 2 && item.ping >= 2000"
                  ></div>
                  <div
                    class="tipsicon tipstext-success"
                    v-else-if="item.state == 2 || isCheck == 0"
                  ></div>
                  <div
                    class="tipsicon tipstext-success-err"
                    v-else-if="item.state == 3 || item.state == 4"
                  ></div>

                  <div
                    v-if="(item.isEnableCopyBtn==1)"
                    class="xbtn-copy"
                    @click.stop="
                      copy(
                        item.jumpLink,
                        `已复制线路${item.index + 1}(${item.jumpLink})`
                      )
                    "
                  >
                    <img class="xbtn-copy-img" src="./icon/copy-link.svg" />
                  </div>
                </div>
              </div>
            </div>
            <div class="main" style="margin-top: 40px">
              <button class="btn" @click="reload">重新检测</button>
            </div>
          </div>
        </div>
        <div v-else>
          <div v-if="state == 0 || state == 1" class="main">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="48px"
              height="60px"
              viewBox="0 0 24 30"
              style="enable-background: new 0 0 50 50"
              xml:space="preserve"
            >
              <rect x="0" y="9.22656" width="4" height="12.5469" fill="#53c957">
                <animate
                  attributeName="height"
                  attributeType="XML"
                  values="5;21;5"
                  begin="0s"
                  dur="0.6s"
                  repeatCount="indefinite"
                ></animate>
                <animate
                  attributeName="y"
                  attributeType="XML"
                  values="13; 5; 13"
                  begin="0s"
                  dur="0.6s"
                  repeatCount="indefinite"
                ></animate>
              </rect>
              <rect
                x="10"
                y="5.22656"
                width="4"
                height="20.5469"
                fill="#53c957"
              >
                <animate
                  attributeName="height"
                  attributeType="XML"
                  values="5;21;5"
                  begin="0.15s"
                  dur="0.6s"
                  repeatCount="indefinite"
                ></animate>
                <animate
                  attributeName="y"
                  attributeType="XML"
                  values="13; 5; 13"
                  begin="0.15s"
                  dur="0.6s"
                  repeatCount="indefinite"
                ></animate>
              </rect>
              <rect
                x="20"
                y="8.77344"
                width="4"
                height="13.4531"
                fill="#53c957"
              >
                <animate
                  attributeName="height"
                  attributeType="XML"
                  values="5;21;5"
                  begin="0.3s"
                  dur="0.6s"
                  repeatCount="indefinite"
                ></animate>
                <animate
                  attributeName="y"
                  attributeType="XML"
                  values="13; 5; 13"
                  begin="0.3s"
                  dur="0.6s"
                  repeatCount="indefinite"
                ></animate>
              </rect>
            </svg>
            <p class="tips">获取域名节点中 请等待...</p>
          </div>
          <div v-else-if="state == 2" class="main">
            <button class="btn" @click="go">Go</button>
            <p class="tips">域名节已获取 未跳转请点击按钮</p>
          </div>
          <div v-else-if="state == 3" class="main">
            <button class="btn" @click="reload">重试</button>
            <p class="tips-ip" v-if="cip">{{ cip }}</p>
            <p class="tips">未找到可用的域名节点 请重试或联系管理员[abt]</p>
          </div>
          <div v-else-if="state == 4" class="main">
            <button class="btn" @click="reload">重试</button>
            <p class="tips-ip" v-if="cip">{{ cip }}</p>
            <p class="tips">访问受限 请重试或联系管理员[ipxz]</p>
          </div>
          <div v-else-if="state == 5" class="main">
            <button class="btn" @click="reload">重试</button>
            <p class="tips-ip" v-if="cip">{{ cip }}</p>
            <p class="tips">未找到域名配置 请重试或联系管理员</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Uri from "jsuri";
import signMd5Utils from "./signMd5Utils";
import tools from "./tools";
const KEY_DOMAIN = require(`../key-domain`);
const { Decrypt } = require("../crypto");

const PRS_DOMAIN_CONFIG = JSON.parse(
  Decrypt(DOMAIN_CONFIG, KEY_DOMAIN.key, KEY_DOMAIN.iv)
);
const PRS_DOMAIN_CDN_CONFIG = JSON.parse(
  Decrypt(DOMAIN_CDN_CONFIG, KEY_DOMAIN.key, KEY_DOMAIN.iv)
);
const PRS_SYS_CONFIG = JSON.parse(
  Decrypt(SYS_CONFIG, KEY_DOMAIN.key, KEY_DOMAIN.iv)
);

const parseDomainList = (list) => {
  return list.map((config, index) => {
    const id = `${config.siteCode}${config.token}${index}`;
    let processedDomains = [];
    if (config.domains == "") {
      processedDomains = [];
    } else if (typeof config.domains == "string") {
      processedDomains = config.domains.split(",");
    } else if(config.domains instanceof Array) {
      processedDomains = config.domains
    }
    processedDomains = processedDomains.filter(domainCotent => {
      return typeof domainCotent == "string" || domainCotent.isEnable == 1
    }).map((domainCotent, domainIndex) => {
      const domainContentIsString = typeof domainCotent == "string"
      const domain = domainContentIsString ? domainCotent : domainCotent.domain
      const uri = new Uri(domain);
      return {
        domain,
        id: `${config.siteCode}${config.token}${index}${domain}${domainIndex}`,
        domainHost: `${uri.protocol()}://${uri.host()}`,
        index: domainIndex,
        isEnableCopyBtn: domainContentIsString ? 1 : (domainCotent.isEnableCopyBtn != undefined ? domainCotent.isEnableCopyBtn : 1),
        isLoadMobileprovision: domainContentIsString ? 0 : domainCotent.isLoadMobileprovision,
        loadMobileprovisionHref: domainContentIsString ? 0 : domainCotent.loadMobileprovisionHref,
        pingText: domainContentIsString ? "" : domainCotent.pingText,
        pingTextColor: domainContentIsString ? "" : domainCotent.pingTextColor,
      };
    });
    return {
      ...config,
      domains: processedDomains,
      id,
    };
  });
};

const parseDomainCdnList = (list) => {
  return list.map((config, index) => {
    const id = `${config.siteCode}${config.token}${index}`;
    let processePaths = config.paths;
    if (config.paths && config.paths.length) {
      processePaths = processePaths.map((path, pathIndex) => {
        const uri = new Uri(path);
        return {
          ...path,
          id: `${config.siteCode}${config.token}${index}${path.path}${pathIndex}`,
          index: pathIndex,
        };
      });
    }
    return {
      ...config,
      paths: processePaths,
      id,
    };
  });
};

const processedDomainConfig = parseDomainList(PRS_DOMAIN_CONFIG);
const processedDomainCdnConfig = parseDomainCdnList(PRS_DOMAIN_CDN_CONFIG);

const originalinstance = axios.create({
  timeout: 20000,
});

originalinstance.interceptors.request.use((config) => {
  const uuid = tools.guid();
  const sign = signMd5Utils.getSign(uuid);
  config.headers["x-auth-uu"] = uuid;
  config.headers["x-auth-sign"] = sign;

  return config;
});

export default {
  data: () => ({
    isAndroid: false,
    isiOS: false,
    cip: '',
    type: 0, //0 站点域名检测 1 cdn或其他站检测
    siteCode: "",
    token: "",
    optional: 0, //模式 0自动选择线路 1手动选择线路
    routerecord: 0, //跳转模式 0重定向 1正常跳转 (仅 optional 为 1 时生效)
    isCheck: 1, //是否检测线路 0不检测 1检测
    whiteOtherQuery: ["installParams"], //该处配置的参数如果有则会匹配到”其他参数“
    otherQuery: [], //其他参数 会在跳转时携带
    state: 0, //0初始状态 1检测可用域名中还未找到可用的 2检测到可用域名 3所有域名检测后无可用 4访问被限制 5无对应配置域名
    actDomain: "",
    checkDomainEveryState: [],
  }),
  computed: {
    siteCodeList() {
      if (this.siteCode) {
        return this.siteCode.split(",");
      } else {
        return [];
      }
    },
    tokenList() {
      if (this.token) {
        return this.token.split(",");
      } else {
        return [];
      }
    },
    actDomainConfig() {
      let configList = [];

      this.siteCodeList.forEach((siteCode, index) => {
        const token = this.tokenList[index];
        if (
          siteCode == PRS_SYS_CONFIG.allSiteCode &&
          token == PRS_SYS_CONFIG.allToken
        ) {
          configList = [...configList, ...processedDomainConfig];
        } else {
          const config = processedDomainConfig.find(
            (config) => config.siteCode == siteCode && config.token == token
          );
          if (config) {
            configList.push(config);
          }
        }
      });
      return configList.map((config, index) => {
        let processedConfig = {
          ...config,
          id: `${config.id}${index}`,
        };
        processedConfig.domains = processedConfig.domains.map((domainItem) => {
          const processedDomainItem = {
            ...domainItem,
            id: `${domainItem.id}${index}`,
            jumpLink: (() => {
              const uri = new Uri(domainItem.domain);
              this.otherQuery.forEach(query => {
                uri.addQueryParam(query.key, query.value)
              })
              return uri.toString()
            })()
          };
          const promise = () => {
            return new Promise((resolve, reject) => {
              originalinstance
                .post(`${domainItem.domainHost}/melody/api/v1/base/heartbeat`)
                .then(({ data }) => {
                  if (data && (data.code == "12200" || data.code == "10008")) {
                    resolve({
                      ...data,
                      ...processedDomainItem,
                    });
                  } else {
                    reject(processedDomainItem);
                  }
                })
                .catch(() => {
                  reject(processedDomainItem);
                });
            });
          };
          return {
            ...processedDomainItem,
            promise,
          };
        });
        return processedConfig;
      });
    },
    actDomainCdnConfig() {
      let configList = [];

      this.siteCodeList.forEach((siteCode, index) => {
        const token = this.tokenList[index];
        if (
          siteCode == PRS_SYS_CONFIG.allSiteCode &&
          token == PRS_SYS_CONFIG.allToken
        ) {
          configList = [...configList, ...processedDomainCdnConfig];
        } else {
          const config = processedDomainCdnConfig.find(
            (config) => config.siteCode == siteCode && config.token == token
          );
          if (config) {
            configList.push(config);
          }
        }
      });
      return configList.map((config, index) => {
        let processedConfig = {
          ...config,
          id: `${config.id}${index}`,
        };
        processedConfig.paths = processedConfig.paths.map((pathItem) => {
          const processedPathItem = {
            ...pathItem,
            id: `${pathItem.id}${index}`,
            jumpLink: (() => {
              const uri = new Uri(config.domain);
              uri.setPath(pathItem.path)
              this.otherQuery.forEach(query => {
                uri.addQueryParam(query.key, query.value)
              })
              return decodeURIComponent(uri.toString())
            })()
          };
          const promise = () => {
            return new Promise((resolve, reject) => {
              originalinstance[pathItem.httpType ? pathItem.httpType : "get"](
                `${config.domain}${pathItem.path}`
              )
                .then(({ data }) => {
                  resolve({
                    ...data,
                    ...processedPathItem,
                  });
                })
                .catch(() => {
                  reject(processedPathItem);
                });
            });
          };
          return {
            ...processedPathItem,
            promise,
          };
        });
        return processedConfig;
      });
    },
    domainEveryStateConfig() {
      return this.actDomainConfig.map((config) => {
        return {
          ...config,
          domains: config.domains.map((domainItem) => {
            return {
              ...domainItem,
              ...this.getCheckDomainEveryState(domainItem.id),
            };
          }),
        };
      });
    },
    domainCdnEveryStateConfig() {
      return this.actDomainCdnConfig.map((config) => {
        return {
          ...config,
          paths: config.paths.map((pathItem) => {
            return {
              ...pathItem,
              ...this.getCheckDomainEveryState(pathItem.id),
            };
          }),
        };
      });
    },
  },
  created() {
    this.getIp()
    this.init();
    setInterval(this.setCip, 1000);
    this.openBrowser();
  },
  destroyed() {
    clearInterval(this.setCip);
  },
  methods: {
    getIp() {
      axios.get('https://v4.ident.me').then(res => {
        this.cip = res.data
      })
    },
    openBrowser() {
      let type = this.isWeixin();
      var u = navigator.userAgent;
      var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      console.log(type, isiOS, isAndroid);
      if (type == 1 && isiOS) {
        this.isiOS = true;
      } else if (type == 1 && isAndroid) {
        this.isAndroid = true;
      } else if (type == 2 && isiOS) {
        this.isiOS = true;
      } else if (type == 2 && isAndroid) {
        this.isAndroid = true;
      }
    },
    isWeixin() {
      var u = navigator.userAgent;
      var ua = navigator.userAgent.toLowerCase();
      if (
        ua.match(/MicroMessenger/i) == "micromessenger" ||
        ua.match(/WeiBo/i) == "weibo"
      ) {
        return 1; //微信
      } else if (ua.match(/QQ\/[0-9]/i)) {
        return 2; //安卓qq
      }
      return 0;
    },
    setCip() {
      if (!this.cip && window.returnCitySN && window.returnCitySN.cip) {
        this.cip = window.returnCitySN.cip;
      }
    },
    init() {
      const uri = new Uri(window.location.href);
      const type = uri.getQueryParamValue("type");
      const optional = uri.getQueryParamValue("optional");
      const routerecord = uri.getQueryParamValue("routerecord");
      const token = uri.getQueryParamValue("token");
      const siteCode = uri.getQueryParamValue("siteCode");
      const isCheck = uri.getQueryParamValue("isCheck");

      this.type = type ? Number(type) : this.type;
      this.optional = optional ? Number(optional) : this.optional;
      this.routerecord = routerecord ? Number(routerecord) : this.routerecord;
      this.token = token ? token : this.token;
      this.siteCode = siteCode ? siteCode : this.siteCode;
      this.isCheck = isCheck ? Number(isCheck) : this.isCheck;

      this.whiteOtherQuery.forEach(item => {
        if(uri.hasQueryParam(item)) {
          this.otherQuery.push({key: item, value: uri.getQueryParamValue(item)})
        }
      })

      if (type == 1) {
        this.checkDomainCdnEvery();
      } else {
        if (this.optional == 1) {
          if (isCheck != 0) {
            this.checkDomainEvery();
          }
        } else {
          this.checkDomain();
        }
      }
    },
    checkDomain() {
      const actDomainConfig = this.actDomainConfig;
      if (actDomainConfig.length && actDomainConfig[0].domains.length) {
        const promiseList = actDomainConfig[0].domains.map((item) =>
          item.promise()
        );
        this.state = 1;
        Promise.any(promiseList)
          .then((data) => {
            if (data.resCode == "10008") {
              this.state = 4;
            } else {
              this.actDomain = data.jumpLink;
              this.go();
              this.state = 2;
            }
          })
          .catch((e) => {
            this.state = 3;
          });
      } else {
        this.state = 5;
      }
    },
    checkDomainEvery() {
      const actDomainConfig = this.actDomainConfig;
      actDomainConfig.forEach((config) => {
        config.domains.forEach((domainItem) => {
          const startTime = new Date();
          this.setCheckDomainEveryState({
            id: domainItem.id,
            ping: -1,
            state: 1,
          });
          domainItem
            .promise()
            .then((data) => {
              const endTime = new Date();
              const c = {
                id: data.id,
                ping: endTime.getTime() - startTime.getTime(),
              };
              if (data.resCode == "10008") {
                this.setCheckDomainEveryState({
                  ...c,
                  state: 4,
                });
              } else {
                this.setCheckDomainEveryState({
                  ...c,
                  state: 2,
                });
              }
            })
            .catch((data) => {
              this.setCheckDomainEveryState({
                id: data.id,
                ping: -1,
                state: 3,
              });
            });
        });
      });
    },
    checkDomainCdnEvery() {
      this.actDomainCdnConfig.forEach((config) => {
        config.paths.forEach((pathItem) => {
          const startTime = new Date();
          this.setCheckDomainEveryState({
            id: pathItem.id,
            ping: -1,
            state: 1,
          });
          pathItem
            .promise()
            .then((data) => {
              const endTime = new Date();
              const c = {
                id: data.id,
                ping: endTime.getTime() - startTime.getTime(),
              };
              this.setCheckDomainEveryState({
                ...c,
                state: 2,
              });
            })
            .catch((data) => {
              this.setCheckDomainEveryState({
                id: data.id,
                ping: -1,
                state: 3,
              });
            });
        });
      });
    },
    getCheckDomainEveryState(id) {
      return this.checkDomainEveryState.find((actItem) => actItem.id == id);
    },
    setCheckDomainEveryState(item) {
      const act = this.checkDomainEveryState.find(
        (actItem) => actItem.id == item.id
      );
      if (act) {
        Object.assign(act, item);
      } else {
        this.checkDomainEveryState.push(item);
      }
    },
    go() {
      window.location.replace(this.actDomain);
    },
    goSite(item) {
      //isLoadMobileprovision
      this.routerecord == 0
        ? window.location.replace(item.jumpLink)
        : (window.location.href = item.jumpLink);
      if(item.isLoadMobileprovision == 1) {
        setTimeout(() => {
          window.location.href = item.loadMobileprovisionHref ? item.loadMobileprovisionHref : './certificate/default.mobileprovision'
        }, 3000)
      }
    },
    reload() {
      window.location.reload();
    },
    copy(text, toast) {
      this.$copyText(text);
      this.$toasted.show(toast ? toast : "已复制", {
        theme: "bubble",
        duration: 3000,
      });
    },
  },
};
</script>

