import Vue from 'vue'
import App from './App.vue'
import VueClipboard from 'vue-clipboard2'
import Toasted from 'vue-toasted'

Promise.any = ps => new Promise((resolve, reject) => {
  let cnt = 0;
  ps.map(p => p.then(resolve).catch((err) => ++cnt === ps.length && reject(err)))
})

Vue.use(VueClipboard)
Vue.use(Toasted)
new Vue({
  render: h => h(App),
}).$mount('#app')
